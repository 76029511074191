<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">查找</div>
      <div class="topRight"></div>
    </div>
    <div class="tjhy">添加好友</div>
    <div class="inpBtn">
      <div>
        <input type="text" placeholder="请输入手机号" v-model="search.mobile"/>
        <img src="../../image/quanz/cz.png" alt="" @click="goSeekResult()" />
      </div>
    </div>
    <div class="tzBox" @click="goCondition()">
      <div><img src="../../image/quanz/s.png" alt="" /></div>
      <div>按条件查找好友</div>
      <div><img src="../../image/quanz/j.png" alt="" /></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        mobile: '',
      },
    };
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 查找结果
    goSeekResult() {
      if (this.search.mobile == '') {
        alert("手机号不能为空！")
        return ;
      } else {
        this.$router.push({ path: "/seekResult", query: {"mobile" : this.search.mobile} });
      }
    },
    // 条件查找页面
    goCondition() {
      this.$router.push({ path: "/condition" });
    }
  },
};
</script>

<style scoped>
.setBox {
  background: #f6f8fa;
  height: 100vh;
}
.boxTop {
  width: 750px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom: 18px;
  background-color: #fff;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.topRight {
  width: 48px;
  height: 48px;
  margin-right: 32px;
}
.tjhy {
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  background: #fff;
  padding: 32px;
}
.inpBtn {
  height: 104px;
  width: 750px;
  background: #fff;
}
.inpBtn div {
  width: 686px;
  height: 72px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 32px;
}

.inpBtn div input {
  width: 300px;
  height: 56px;
  border: none;
  background: #f6f8fa;
  font-size: 24px;
  color: #97a4b4;
  margin-left: 12px;
}
.inpBtn div img {
  width: 120px;
  height: 56px;
}
.tzBox {
  width: 750px;
  height: 96px;
  background: #fff;
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 20px;
}
.tzBox div:nth-child(1) img {
  width: 32px;
  height: 32px;
  display: flex;
  margin-left: 32px;
  margin-right: 30px;
}
.tzBox div:nth-child(3) img {
  width: 40px;
  height: 40px;
  display: flex;
  margin-left: 388px;
}
</style>
